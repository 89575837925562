"use client";

import { cn, twMerge } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { forwardRef } from "react";
import CustomLink from "../other/CustomLink";

const variants = cva(
  [
    "inline-flex",
    "box-border",
    "whitespace-nowrap",
    "items-center",
    "justify-center",
    "relative",
    "cursor-pointer",
    "disabled:cursor-not-allowed",
    "transition-all",
    "duration-200",
    "outline-none",
    "outline-offset-0",
    "gap-3",
    "w-fit",
    "select-none",
    "group-hover:after:scale-x-100",
    "font-sairaSemiCondensed",
    "text-14",
    "font-[500]",
    "leading-[1.7]",
    "py-[11px]",
  ],
  {
    variants: {
      intent: {
        primary: [
          "px-6",
          "rounded-[6px]",
          "border",
          "border-transparent",

          "bg-navy-blue",
          "text-white",
          "text-left",

          "enabled:hover:bg-navy-blue-80",

          "enabled:active:bg-navy-blue-20",
          "enabled:active:text-black",

          "focus-visible:outline-mellow",

          "disabled:bg-navy-blue-10",
          "disabled:text-black-40",
        ],
        secondary: [
          "px-6",
          "rounded-[6px]",
          "border",
          "bg-transparent",
          "border-black",
          "text-black",

          "enabled:hover:bg-[#0000001A]",

          "enabled:active:bg-[#0000000D]",

          "focus-visible:outline-royal-blue",
          "focus-visible:border-royal-blue",

          "disabled:text-black-40",
          "disabled:border-black-40",
        ],
        ghost: [
          "bg-transparent",
          "text-black",
          "outline-offset-2",

          "after:absolute",
          "after:h-[1px]",
          "after:bottom-1",
          "after:left-0",
          "after:right-0",
          "after:origin-top-left",
          "after:bg-black",
          "after:transition",
          "after:scale-x-0",

          "hover:after:scale-x-100",

          "enabled:active:text-black-60",
          "enabled:active:after:bg-black-60",
          "enabled:active:after:scale-x-100",

          "disabled:text-black-40",
          "disabled:after:bg-black-40",
          "disabled:after:scale-x-100",

          "focus-visible:outline-royal-blue",
          "focus-visible:after:scale-x-100",
        ],
      },

      bg: { dark: [], light: [] },
    },
    compoundVariants: [
      {
        intent: "primary",
        bg: "dark",
        className:
          "bg-mellow text-black enabled:hover:bg-mellow-80 active:bg-mellow-20 focus-visible:outline-white disabled:bg-dijon-10 disabled:text-black-40",
      },
      {
        intent: "secondary",
        bg: "dark",
        className: `border-white text-white enabled:hover:bg-[#FCFCFC66] enabled:hover:border-[#FCFCFC] 
          enabled:active:bg-black-5 enabled:active:text-black
          focus-visible:outline-mellow focus-visible:border-mellow disabled:border-black-40 disabled:text-black-40`,
      },
      {
        intent: "ghost",
        bg: "dark",
        className: `text-white after:bg-white enabled:hover:after:bg-white active:text-black-10 active:after:bg-black-10 
          focus-visible:outline-mellow focus-visible:after:bg-white focus-visible:after:scale-x-100
          disabled:text-black-40 disabled:after:bg-black-40 disabled:after:scale-x-100`,
      },
    ],
    defaultVariants: {
      intent: "primary",
      bg: "light",
    },
  }
);

type AppButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof variants> & {
    type?: string;
    size?: string;
    bg?: string;
    label: string;
    intent?: string;
    link?: string;
    fullLength?: boolean;
    ariaLabel?: string;
    afterCss?: string;
  };

const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  (
    {
      type = "button",
      className,
      intent,
      bg,
      label,
      link,
      afterCss = "after:bottom-1",
      fullLength,
      ariaLabel,
      ...rest
    },
    ref
  ) => {
    const CoreButton = () => (
      <button
        type={type}
        ref={ref}
        className={twMerge(
          cn(
            variants({
              intent,
              bg,
              className,
            }),
            afterCss
          )
        )}
        {...(ariaLabel && { "aria-label": ariaLabel })}
        {...rest}
      >
        {label}
      </button>
    );

    return link ? (
      <CustomLink ariaLabel={ariaLabel} href={link} className={cn("w-fit", fullLength && "w-full")} tabIndex={-1}>
        <CoreButton />
      </CustomLink>
    ) : (
      <div className={cn("w-fit", fullLength && "w-full")}>
        <CoreButton />
      </div>
    );
  }
);

AppButton.displayName = "AppButton";

export { AppButton };
export default AppButton;
