"use client";

import { useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import Image from "next/image";

type Props = {
  className?: string;
  isLargeScreen?: boolean;
  linkToPlatform?: string;
  avatarIconUrl: string;
  email?: string | null;
  portalLoginCtaLink: string;
  label: string;
};

const MobileProfileButton = ({ avatarIconUrl, email, portalLoginCtaLink, label, linkToPlatform }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover onOpenChange={setOpen}>
      <PopoverTrigger className={cn("gap-2 h-8 flex items-center ")}>
        <div className="flex items-center justify-center rounded-full">
          <Image
            src={avatarIconUrl}
            width="48"
            height="48"
            alt={email ?? "Default user profile icon"}
            className="object-cover w-10 h-10 rounded-full"
          />
        </div>

        <ChevronDown size={16} className={cn("transition", open && "rotate-180")} aria-label="chevron down icon" />
      </PopoverTrigger>

      <PopoverContent className="w-full p-2 rounded-[0px] bg-black-10 shadow-2xl border-[1px] mt-3 z-[300]">
        <button
          aria-label="profile button"
          className={`flex outline-none pr-3 pl-2   focus-visible:outline-dijon   w-fit 
        justify-center items-center gap-2  `}
          onClick={() => {
            const finalLink = portalLoginCtaLink || linkToPlatform;
            finalLink && (document.location = finalLink);
          }}
        >
          {label && <span className="text-royal-blue text-14 font-[500] font-sairaSemiCondensed">{label}</span>}
          <ChevronRight size={16} aria-label="chevron right icon" />
        </button>
      </PopoverContent>
    </Popover>
  );
};

export default MobileProfileButton;
